import { Component, OnInit } from '@angular/core';
import { Stimulsoft } from 'stimulsoft-reports-js';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'Ramsoft';
  // options: any = new Stimulsoft.Viewer.StiViewerOptions();
	// viewer: any = new Stimulsoft.Viewer.StiViewer(this.options, 'StiViewer', false);

	ngOnInit() {
		// var report = Stimulsoft.Report.StiReport.createNewReport();
    // Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnk/Gs7eA4KXjwGveeOQlAH0ow0dhjlfXZ+zOKsxLKcMfvPbe" + 
    // "aztc9Zig2g9ahJagTZktL/6Pr/eqJgGywwMmMuZCFi7kX4RXuISXMq0Am2Eco231nF830Acl4crYp1pHT6eUxG9Mx6" + 
    // "IO4o0LOKScW5ylfdrs0JsFyBaWVG9sjSkT+SU9tgsk4L9uzBBe4NOwsCYE0xEXXRaQfmCvKu6T2nQVWumHhaPyF5OT" + 
    // "XeaIpS5OmeocIUAbiYdfpqf+bOZrfFY87tCfRa597uF5OmvJFnlIdNTOuirLPcNWEYnN/3tAv7DRb8KjqTM0XOl9el" + 
    // "dNUXopddaCAXmYqvQxLoOezSrf3Ipd20bTgjF5xQEDsbn1G+UGWqHbb1PwJH/gKMnMqh8NyIM2+1mfgciAc2iAUk0L" + 
    // "pOyzn7NdXUCqyW58dQlAWoAEGe46M5C7VPx285WE6azF6Z2StkvcM0q+lxdF0KYNq/rFWMM/9fJVlm0VQtDIWSg1JQ" + 
    // "WT4PQB0PBlJ8474feL0RcZyMiZVEhTIzIWyU";
		// report.loadFile("/assets/reports/Report.mrt");

		// this.viewer.report = report;
		// this.viewer.renderHtml("viewerContent");
	}
}
