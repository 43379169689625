// import { EncrDecrService } from './EncrDecr.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take, map, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { IUser } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<IUser>;

  // currentUser: BehaviorSubject<IUser> = new BehaviorSubject(null);

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
   // private env: EncrDecrService
  ) {
    //      console.log('Auth Service Constructor Begin');
    try {
      this.user$ = this.afAuth.authState.pipe(
        switchMap((user: any) => {
          //        console.log(user);
          if (user) {
            return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
          } else {
            //        console.log('else', user);
            return of(null);
          }
        })
      );
    } catch (err) {
      // console.log('err');
      //  console.log(err);
    }
  }

  getUser() {
    return this.user$.pipe(first()).toPromise();
  }

  uid() {
    return this.user$
      .pipe(
        take(1),
        map((u: any) => u && u.uid)
      )
      .toPromise()
      .then((u) => console.log(''))
      .catch((err) => console.log('err', err));
  }

  getUid() {
    return this.user$
      .pipe(
        take(1),
        map((u: any) => u && u.uid)
      )
      .toPromise();
  }

  async signIn(email: string, password: string): Promise<string> {
    return await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        // console.log('succ');
        this.router.navigateByUrl('/home');
        return 'success';
      })
      .catch((err) => {
        //  console.log('aj', err.message);
        return err.message;
        //  this.alertnLoading.showAlert(err, 'Login',  '');
      });
  }

  login(email, password) {
   // console.log(email, password);

    //  password = this.env.set('112411919112', password);

    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async register(email, password): Promise<any> {
    return await this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  changePassword(newpassword) {
    return null;
    // return this.afAuth.currentUser.updatePassword(newpassword);
  }

  async sendPasswordReset(email) {
    return await this.afAuth.sendPasswordResetEmail(email);
  }

  async logout() {
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }
}
