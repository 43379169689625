import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
// import { ShopActionService } from 'src/app/landing/shop/services/shopaction.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { faBoxOpen, faCalculator, faChartPie, faHeart, faIdBadge, faShippingFast, faShoppingCart, faSignOutAlt, faUniversity, faUser, faUserCog, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: [
    './styles/topbar.component.scss',
    './styles/topbar.ios.scss',
    './styles/topbar.md.scss',
    './styles/topbar.responsive.scss',
  ],
})
export class TopBarComponent implements OnInit {

  faUser = faUser;
  faDeliveryBoys = faUserCircle;
  faWareHouse = faUniversity;
  faSettings = faUserCog;
  faWishlist = faHeart;
  faCart = faShoppingCart;
  faOrders = faBoxOpen;
  faShipping = faShippingFast;
  faLogout = faSignOutAlt;
  faRaw = faChartPie;
  fauseful = faInfoCircle;
  faCalculator = faCalculator;
  faTemplate = faIdBadge;

  topbarData;
  ITopbar;

  fy;

  public items: Array<string> = ['తెలుగు', 'हिंदी', 'English'];

  private value = {};
  private disabled = false;

  user;
  private toggleButton: any;
  private sidebarVisible = false;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    // const toggleButton = this.toggleButton;
    // const html = document.getElementsByTagName('html')[0];
    // html.classList.remove('nav-open');

    // this.sidebarVisible = false;

    this.fy = localStorage.getItem('fy');

    console.log(this.fy);

    this.authService.user$.subscribe((usr) => (this.user = usr));
  }

  public selected(value) {
   // console.log('Selected value is: ', value);
  }

  public removed(value) {
  //  console.log('Removed value is: ', value);
  }

  public typed(value) {
  //  console.log('New search input: ', value);
  }

  public refreshValue(value) {
    this.value = value;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  logout() {
    this.authService.logout();
  }

  showMyProfile() {
    this.router.navigateByUrl(`/web/${this.user.uid}`);
  }

  goToAccount() {
    // this.sas.actionService.next('account');

    this.router.navigateByUrl('/shop');
  }

  goToWishList() {
    //  this.sas.actionService.next('wishlist');

    this.router.navigateByUrl('/shop');
  }

  goToCart() {
    //  this.sas.actionService.next('cart');

    this.router.navigateByUrl('/shop');
  }

  goToOrders() {
    // this.sas.actionService.next('orders');

    this.router.navigateByUrl('/shop');
  }

  goToSettings() {
    this.router.navigateByUrl('/settings');
  }
}
