<nav class="navbar navbar-expand-lg fixed-top">
  <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
    <div class="navbar-translate">
      <a class="navbar-brand title" routerLink="/home">Ramsoft E-Invoice System</a>
      <button class="navbar-toggler" type="button"  data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-bar top-bar"></span>
        <span class="navbar-toggler-bar middle-bar"></span>
        <span class="navbar-toggler-bar bottom-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" data-nav-image="./assets/img//blurred-image-1.jpg" data-color="orange">
      <ul class="navbar-nav ml-auto ">
        <h1 class="h1-yr">Financial Year : <span>{{fy || ''}}</span></h1>
        <!-- <div class="form-group" style="margin: 0;">
          <select class="form-select" >
            <option selected>2021-2022</option>
            <option >2022-2023</option>
          </select>
        </div> -->
       
        <li class="nav-item" routerLink="/">
          <a class="nav-link">
            <p>Back to Main Website</p>
          </a>
        </li>
        <!-- <li class="nav-item" routerLink="/home/products">
          <a class="nav-link">
            <p>Products</p>
          </a>
        </li> -->
        <li class="nav-item" routerLink="/gst/dashboard">
          <a class="nav-link">
            <p>GST</p>
          </a>
        </li>

        <li class="nav-item" routerLink="/home/companyprofile">
          <a class="nav-link">
            <p>Company Profile</p>
          </a>
        </li>
       <!--  <li class="nav-item" routerLink="/subscriptions">
          <a class="nav-link">
            <p>Cancel-Invoices</p>
          </a>
        </li>
        -->



        <div *ngIf="user">
          <div class="li-nav-flex">
            <!-- <img [src]="user.photoURL || 'assets/img/no-image.png'" class="img-nav-profile"> -->
            <div class="div-user-letter">
              <h6>{{user.name.substr(0,1)}}</h6>
            </div>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle nav-link-username" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-expanded="false" style="padding-left: 5px;">
                <p>{{user.name }}</p>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink1">
                <a class="dropdown-item" routerLink="/home/usefullinfo">
                  <fa-icon [icon]="fauseful"></fa-icon>Useful Info</a>
                <a class="dropdown-item" (click)="goToSettings()">
                  <fa-icon [icon]="faSettings"></fa-icon>Settings</a>
                <a class="dropdown-item" (click)="logout()">
                  <fa-icon [icon]="faLogout"></fa-icon>Logout</a>
              </div>
            </li>
          </div>
        </div>
        <!-- <li class="nav-item" routerLink="/settings/settingstab">
          <a class="nav-link">
            <p>{{topbarData.settings}}</p>
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="logout()">
            <p>{{topbarData.logout}}</p>
          </a>
        </li> -->
      </ul>
    </div>
    <!-- <div class="div-plans">
        <div style="text-align: center;" (click)="showMyProfile()">
          <p class="div-planstatus">{{topbarData.freePlan}}</p>
        </div>
        <div class="div-changeplan" routerLink="/subscriptions">
          <p >{{topbarData.changePlan}}</p>
          <img src="assets/img/right-arrow.png">
        </div>

    </div> -->
  </div>
</nav>
